import { Article } from 'src/services/articles/types/article';
import React from 'react';
import styled from 'styled-components';
import { ARTICLES_LAYOUTS_GAP } from 'src/constants/layout';
import ArticlesGroupItem from '../ArticlesGroupItem';

const gap = ARTICLES_LAYOUTS_GAP;

export default function LayoutStack({
  articles,
  numberOfColumns = 4,
}: {
  articles: Article[];
  numberOfColumns?: 2 | 4;
}) {
  return (
    <LayoutStackCss numberOfColumns={numberOfColumns}>
      {articles.slice(0, numberOfColumns).map((art, idx) => (
        <ArticlesGroupItem
          key={`article-${idx}`}
          variant={'vertical'}
          article={art}
        />
      ))}
    </LayoutStackCss>
  );
}

const LayoutStackCss = styled.div<{ numberOfColumns: 2 | 4 }>`
  & > div {
    width: auto;
    min-width: 0;
  }

  ${p => p.theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: repeat(${p => p.numberOfColumns}, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: ${gap};
    grid-row-gap: ${gap};

    ${p => p.theme.breakpoints.maxLg} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  ${p => p.theme.breakpoints.lg} {
    & > div:first-child {
      grid-area: 1 / 1 / 2 / 2;
    }

    & > div:nth-child(2) {
      grid-area: 1 / 2 / 2 / 3;
    }

    & > div:nth-child(3) {
      grid-area: 1 / 3 / 2 / 4;
    }

    & > div:nth-child(4) {
      grid-area: 1 / 4 / 2 / 5;
    }
  }

  ${p => p.theme.breakpoints.maxSm} {
    display: flex;
    flex-direction: column;
    & > div:not(:last-child) {
      margin-bottom: ${gap};
    }
  }
`;
