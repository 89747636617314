import { Article } from 'src/services/articles/types/article';
import React from 'react';
import styled from 'styled-components';
import { ArticleCardWithImageVariant } from '@shapegames/web-library-quick-draw-ui/dist/AticleCardWithImage';
import { ARTICLES_LAYOUTS_GAP } from 'src/constants/layout';
import ArticlesGroupItem from '../ArticlesGroupItem';

const gap = ARTICLES_LAYOUTS_GAP;

export default function LayoutGridStack({ articles }: { articles: Article[] }) {
  const setup = [
    ['vertical', 'vertical', 'vertical'],
    ['vertical', 'vertical', 'vertical'],
    ['vertical', 'horizontalSquared', 'vertical'],
    ['vertical', 'vertical', 'vertical', 'horizontalSquared'],
    ['vertical', 'vertical', 'vertical'],
  ] as ArticleCardWithImageVariant[];

  return (
    <LayoutGridStackCss>
      {articles.slice(0, 5).map((art, idx) => (
        <ArticlesGroupItem
          variant={setup[idx]}
          key={`article-${idx}`}
          article={art}
        />
      ))}
    </LayoutGridStackCss>
  );
}

const LayoutGridStackCss = styled.div`
  & > div {
    min-width: 0;
  }

  ${p => p.theme.breakpoints.maxSm} {
    display: flex;
    flex-direction: column;
    & > div:not(:last-child) {
      margin-bottom: ${gap};
    }
  }

  ${p => p.theme.breakpoints.sm} {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: ${gap};
    grid-row-gap: ${gap};

    ${p => p.theme.breakpoints.maxLg} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);

      & > div:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }

      & > div:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
      }

      & > div:nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;
      }

      & > div:nth-child(4) {
        grid-area: 3 / 1 / 4 / 2;
      }

      & > div:nth-child(5) {
        grid-area: 3 / 2 / 4 / 3;
      }
    }
  }

  ${p => p.theme.breakpoints.lg} {
    ${p => p.theme.breakpoints.maxXl} {
      & > div:first-child {
        grid-area: 1 / 1 / 2 / 3;
      }

      & > div:nth-child(2) {
        grid-area: 1 / 3 / 2 / 5;
      }

      & > div:nth-child(3) {
        grid-area: 1 / 5 / 2 / 7;
      }

      & > div:nth-child(4) {
        grid-area: 2 / 1 / 3 / 4;
        height: auto;
      }

      & > div:nth-child(5) {
        grid-area: 2 / 4 / 3 / 7;
      }
    }
  }

  ${p => p.theme.breakpoints.xl} {
    & > div:first-child {
      grid-area: 1 / 1 / 2 / 3;
    }

    & > div:nth-child(2) {
      grid-area: 1 / 3 / 2 / 5;
    }

    & > div:nth-child(3) {
      grid-area: 1 / 5 / 2 / 7;
    }

    & > div:nth-child(4) {
      grid-area: 2 / 1 / 3 / 5;
      height: auto;
    }

    & > div:nth-child(5) {
      grid-area: 2 / 5 / 3 / 7;
    }
  }
`;
