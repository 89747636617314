import React from 'react';
import { useRouter } from 'next/router';
import Loading from 'src/components/Loading';
import ErrorPage from 'src/components/ErrorPage';
import * as Sentry from '@sentry/nextjs';

interface RouterFallbackProps {
  children: React.ReactNode;
  error: boolean;
  loading?: boolean;
}

const RouterFallback: React.FC<RouterFallbackProps> = ({
  children,
  error,
  loading,
}) => {
  const router = useRouter();

  if (router.isFallback || loading) return <Loading />;

  if (error) {
    Sentry.captureException({
      error,
      message: 'failed router fallback',
    });

    return (
      <ErrorPage message={'This page could not be loaded please try again.'} />
    );
  }

  return <>{children}</>;
};

export default RouterFallback;
