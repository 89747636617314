import React from 'react';
import {
  ArticleCardWithImage,
  ArticleCardWithImageVariant,
  ArticleCardWithImageVariantTypes,
  AticleCardWithImageProps,
  useVariantBasedOnScreenSize,
} from '@shapegames/web-library-quick-draw-ui/dist/AticleCardWithImage';
import Link from 'next/link';
import { Article, CropType } from 'src/services/articles';
import styled from 'styled-components';
import { imageUrlMaker, mapCropsToImageSizes } from 'src/utils/image-helpers';
import { mapArticleToProps } from 'src/containers/ArticlesGroup/Item/helpers';
import dynamic from 'next/dynamic';

const Image = dynamic(() => import('next/legacy/image'), {
  ssr: true,
  // eslint-disable-next-line react/display-name
  loading: () => <ImagePlaceholder />,
});

interface ArticlesGroupItemProps
  extends Pick<AticleCardWithImageProps, 'variant'> {
  article: Article;
}

interface ArticleImageProps {
  cropType?: CropType;
  variant?: ArticleCardWithImageVariant;
  article: Article;

  // dev only property to debug the image types used in each article item
  _debug?: boolean;
}

const variantToCrop: Record<ArticleCardWithImageVariantTypes, CropType> = {
  horizontalSquared: 'squareCrop',
  horizontalWide: 'landscapeWideCrop',
  vertical: 'landscapeCrop',
};

function ArticleImage({
  variant = 'horizontalSquared',
  article,
  ...props
}: ArticleImageProps) {
  const usedVariant = useVariantBasedOnScreenSize(variant);
  const cropType = variantToCrop[usedVariant];

  const crop =
    article[cropType] || article['landscapeCrop'] || article['squareCrop'];

  if (!article.image?.url) {
    // maybe return a placeholder here
    return null;
  }

  const url = imageUrlMaker({
    imageUrl: article.image.url,
    cropType,
    crop,
  });

  // use a thumbnail crop as a blurred placeholder
  const placeholderUrl = imageUrlMaker({
    imageUrl: article.image.url,
    cropType: 'thumbnailCrop',
    crop: article.landscapeCrop,
  });

  const sizes = mapCropsToImageSizes[cropType];

  return (
    <BgWrapCss variant={usedVariant} _debug={props._debug}>
      <Image
        src={url}
        objectFit="cover"
        layout="responsive"
        placeholder="blur"
        blurDataURL={placeholderUrl}
        width={sizes.width}
        height={sizes.height}
        quality={100}
        alt={article.image?.alt}
      />
    </BgWrapCss>
  );
}

function ArticlesGroupItem({ article, variant }: ArticlesGroupItemProps) {
  return (
    <div>
      <Link href={`/${article.mainArticleGroup.slug}/${article.slug}`}>
        <ArticleCardWithImage
          variant={variant}
          {...mapArticleToProps(article)}
          imageNode={<ArticleImage variant={variant} article={article} />}
        />
      </Link>
    </div>
  );
}

const BgWrapCss = styled.div<{ variant?: string; _debug?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  /* Styling the image wrapper next adds to the dom */
  > span {
    height: 100% !important;
  }

  ${p =>
    p._debug &&
    `
  &::before {
    content: '${p.variant || ''}';
    z-index: 9999;
    background: red;
    color: white;
    padding: 10px;
    position: absolute;
    top: 0;
    left: 0;
  }
  `}
`;

const ImagePlaceholder = styled.div`
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: ${p => p.theme.colors.gray300};
`;

export default ArticlesGroupItem;
