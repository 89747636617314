import React, { PropsWithChildren, useCallback } from 'react';
import styled from 'styled-components';
import PagingLoader from './PagingLoader';
import useVericalIntersect from 'src/hooks/use-vertical-interesect';
import { Button } from '@shapegames/web-library-quick-draw-ui/dist/Button/Button';

interface InfinityLoadingProps {
  onFetchMore: () => void;
  isLoading: boolean;
  shouldFetchMore: boolean;
  startFetchingOffeset?: number;
  withButton?: boolean;
}

function InfinityLoading({
  onFetchMore,
  shouldFetchMore,
  isLoading,
  startFetchingOffeset = 500,
  children,
  withButton,
}: PropsWithChildren<InfinityLoadingProps>) {
  const handleObserver: IntersectionObserverCallback = useCallback(
    entities => {
      const target = entities[0];
      if (target.isIntersecting && shouldFetchMore) {
        onFetchMore();
      }
    },
    [shouldFetchMore, onFetchMore],
  );
  const loader = useVericalIntersect<HTMLDivElement>(
    handleObserver,
    `${startFetchingOffeset}px`,
  );

  return (
    <>
      {children}
      <PagingLoader ref={withButton ? null : loader} isLoading={isLoading} />
      {withButton && (
        <ButtonContainerCss>
          <ButtonCss
            onClick={() => shouldFetchMore && onFetchMore()}
            loading={isLoading}
            size="sm"
          >
            Load more articles
          </ButtonCss>
        </ButtonContainerCss>
      )}
    </>
  );
}

export default InfinityLoading;

const ButtonContainerCss = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ButtonCss = styled(Button)`
  width: auto;
  padding-left: ${p => p.theme.sizes.spacing4};
  padding-right: ${p => p.theme.sizes.spacing4};
  background-color: ${p => p.theme.colors.neutral900};
`;
