import { useState } from 'react';
import { useSWRConfig } from 'swr';
import useSWRInfinite from 'swr/infinite';
import * as Sentry from '@sentry/nextjs';
import { AxiosResponse } from 'axios';

type GetPaging<T> = (
  data: T | null,
) => { hasMore: boolean; next?: string } | undefined;

interface UseSWRInfiniteWithFallbackArgs<T> {
  fallbackKey: string;
  fetcher: (cursor?: string) => Promise<AxiosResponse<T> | null>;
  getPaging: GetPaging<T>;
  customErrorMessage?: string;
}

export const useSWRInfiniteWithFallback = <T,>({
  fallbackKey,
  fetcher,
  getPaging,
  customErrorMessage,
}: UseSWRInfiniteWithFallbackArgs<T>) => {
  const [isLoading, setIsLoading] = useState(false);
  const { fallback } = useSWRConfig();
  const fallbackData = fallback[fallbackKey] as T;

  const { data, error, setSize, size } = useSWRInfinite(
    (...args) => getSWRKey(fallbackKey, getPaging, ...args),
    async _cursor => {
      try {
        setIsLoading(true);
        const cursor = _cursor.replace(fallbackKey, '');
        const response = await fetcher(cursor);

        return response?.data;
      } catch (err) {
        Sentry.captureException({
          err,
          message: customErrorMessage ?? 'Failed useSWRInfiniteWithFallback',
        });
      } finally {
        setIsLoading(false);
      }
    },
  );

  const fetchMore = () => {
    if (isLoading) return;
    setSize(size + 1);
  };

  return {
    data: (data || [fallbackData]) as T[],
    error,
    isLoading,
    fetchMore,
  };
};

function getSWRKey<T>(
  fallbackKey: string,
  getPaging: GetPaging<T>,
  pageIndex: number,
  previousPageData: T,
) {
  if (pageIndex === 0) return fallbackKey;

  if (previousPageData) {
    const pagingObj = getPaging(previousPageData);
    const hasMore = pagingObj?.hasMore ?? false;
    const next = pagingObj?.next ?? '';

    if (!hasMore || !next) return null;

    return `${fallbackKey}${next}` as string;
  }
}
