export const makeTagSwrKey = (tagId: string) => `SWR-tag-${tagId}`;
export const makeArticleGroupSwrKey = (articleGroupId: string) =>
  `SWR-article-group-${articleGroupId}`;
export const makeArticleSwrKey = (articleSlug: string) =>
  `SWR-article-${articleSlug}`;
export const makeRelatedArticlesSwrKey = (articleSlug: string) =>
  `SWR-related-articles-${articleSlug}`;

export const indexPageSwrKey = 'SWR-index-page';
export const recentArticlesSwrKey = 'SWR-recent-articles';
