import { Article } from 'src/services/articles/types/article';
import formatArticleDate from 'src/utils/format-article-date';

const MAX_NUM_OF_TAGS_IN_ARTICLE_CARD = 4;

export function mapArticleToProps(article: Article) {
  return {
    title: article.title,
    summary: article.summary,
    imageUrl: article.image?.url || undefined,
    author: article.author?.name,
    tags: article.tags
      ? article.tags.slice(0, MAX_NUM_OF_TAGS_IN_ARTICLE_CARD).map(t => t.name)
      : [],
    hasVideo: !!article.videoUrl,
    createdAt: formatArticleDate(article.createdAt),
  };
}
