import { useEffect, useRef } from 'react';

export default function useVericalIntersect<P extends Element>(
  cb: IntersectionObserverCallback,
  distanceFromTarget = '10px',
) {
  const ref = useRef<P | null>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: distanceFromTarget, // distance from the bottom
      threshold: 1.0,
    };
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(cb, options);
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [distanceFromTarget, cb]);

  return ref;
}
