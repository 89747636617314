import { camelize } from '@shapegames/web-library-utils';
import {
  IS_PRODUCTION,
  IS_PREPROD,
  IS_STAGING,
} from 'src/constants/enviroment';
import {
  Tag,
  ArticlesGroupsResponse,
  ArticlesGroup,
  ArticlesByTagResponse,
  Article,
  Advertisement,
  AuthorsResponse,
  MenuItems,
} from './types/article';
import { SitemapDto } from './types/sitemap';
import axios from 'axios';

function getBaseApiUrl() {
  if (IS_PRODUCTION || IS_PREPROD) {
    return 'https://article.article.jk.workload.shapegamescloud.com/api';
  } else if (IS_STAGING) {
    return 'https://article.article.jk.workload.shapegamescloud.com/api'; // temp - should change to below url later
  } else
    return 'https://article.article.jk-dev.workload.shapegamescloud.com/api';
}

type ArticlesApiParams =
  | undefined
  | {
      authToken: string;
    };

const axiosClient = axios.create({
  baseURL: getBaseApiUrl(),
  timeout: 10000,
});

export class ArticlesApi {
  params?: ArticlesApiParams;

  constructor(args?: ArticlesApiParams) {
    this.params = args;

    if (this.params?.authToken) {
      axiosClient.interceptors.request.use(config => {
        config.headers.Authorization = `Bearer ${this.params?.authToken}`;
        return config;
      });
    }

    axiosClient.interceptors.response.use(response => {
      response.data = camelize(response.data);
      return response;
    });
  }

  public async getTags(allTags = false) {
    const params = { shownInMenu: !allTags };
    return await axiosClient.get<Tag[]>('tags', { params });
  }

  public async getAllAuthors() {
    return await axiosClient.get<AuthorsResponse>('authors/all');
  }

  public async getArticleGroups(
    nextPageCursor?: string,
    queryParams?: {
      shownInMainPage?: 'true' | 'false';
    },
  ) {
    let params = {} as Record<string, string>;
    if (nextPageCursor) params['nextPageCursor'] = nextPageCursor;
    if (queryParams) params = { ...params, ...queryParams };

    return await axiosClient.get<ArticlesGroupsResponse>('article_groups', {
      params,
    });
  }

  public async getArticleGroupBySlug(slug: string, nextPageCursor?: string) {
    const params = nextPageCursor ? { cursor: nextPageCursor } : {};
    return await axiosClient.get<ArticlesGroup>(
      `article_groups/${slug}/articles`,
      { params },
    );
  }

  public async getArticlesByTag(tag: string, nextPageCursor?: string) {
    const params = nextPageCursor ? { cursor: nextPageCursor } : {};
    return await axiosClient.get<ArticlesByTagResponse>(
      `tags/${tag}/articles`,
      { params },
    );
  }

  public async getArticleBySlug(slug: string) {
    return await axiosClient.get<Article>(`articles/${slug}`);
  }

  public async getArticlePreviewById(id: string) {
    return await axiosClient.get<Article>(`articles/${id}/preview`);
  }

  public async searchArticles(tags: string[]) {
    const queryString = tags.map(tag => `tags=${tag}`).join('&');
    return await axiosClient.get<ArticlesByTagResponse>(
      `articles/search?${queryString}`,
    );
  }

  public async getRandomAdvertisements(numberOfAdverts?: number) {
    const params = numberOfAdverts ? { n: numberOfAdverts } : {};
    return await axiosClient.get<Advertisement[]>('advertisements/random', {
      params,
    });
  }

  public async getMenuItems() {
    return await axiosClient.get<MenuItems>('menu');
  }

  public async getSitemap() {
    return await axiosClient.get<SitemapDto>('sitemap');
  }
}
