import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);

const timeAgo = new TimeAgo('en-US');

export default function formatArticleDate(date: string) {
  return timeAgo.format(new Date(date));
}
