import styled from 'styled-components';
import React from 'react';
import Spinner from './Spinner';

interface PagingLoaderProps {
  isLoading: boolean;
}

const PagingLoader = React.forwardRef<HTMLDivElement, PagingLoaderProps>(
  (props, ref) => {
    return <Loader ref={ref}>{props.isLoading && <Spinner />}</Loader>;
  },
);

const Loader = styled.div`
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
`;

PagingLoader.displayName = 'PagingLoader';
export default PagingLoader;
