import styled from 'styled-components';
import React from 'react';

function Spinner() {
  return <SpinnerCss />;
}

const SpinnerCss = styled.div`
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.3rem solid ${p => p.theme.colors.gray300};
  border-top-color: ${p => p.theme.colors.black};
  animation: 1.5s spin infinite linear;

  &.multi {
    border-bottom-color: blue;
  }
`;

export default Spinner;
